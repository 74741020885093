$(document).ready(function() {
    $('.select-custom').on('change', function() {
        window.location.assign($(this).val());
    });

    $('.page-header-slider').lightSlider({
        item:1,
        loop:true,
        slideMove:1,
        auto: true,
        mode: 'fade',
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed:600
    });

    $('.toggle-menu').on('click', function (e) {
        e.preventDefault();
       $(this).closest('.side-menu').toggleClass('open');
    });

    $('.tabs a').on('click', function (e) {
        e.preventDefault();
        var tab = $(this).data('tab');
        $(this).addClass('active').siblings().removeClass('active');
        $('.tab-content[data-tab="'+ tab +'"]').addClass('active').siblings().removeClass('active');
    });

    $('.show-data').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('.data-container').toggleClass('open');
    });

    $('.single-subscriber-number:first-of-type').addClass('open').find('.hidden-info').slideDown();

    $('.open-info').on('click', function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('open').find('.hidden-info').slideToggle();
        $(this).parent().siblings('.single-subscriber-number').removeClass('open').find('.hidden-info').slideUp();
    });

    $('.mobile-menu-btn').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('open').siblings('.main-nav').slideToggle();
    });

    if($(window).width()<768) {
        $('.has-dd a').on('click', function (e) {
            e.preventDefault();
            $(this).siblings('ul').slideToggle();
        });

    }

    var $map = $('#map');

    if ($map.length > 0) {
        var pos = new google.maps.LatLng($map.data('lat'), $map.data('lng'));

        var map = new google.maps.Map($map[0], {
            center: pos,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROAD,
            scrollwheel: false
        });

        var contentString = '<div id="content">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<div id="bodyContent">'+
            '<p>София 1309<br>' +
            'бул. „Ал.Стамболийски” №205<br>' +
            'сградата на Нипроруда, корпус А, етаж 3,'+
            'офис 301</p>'+
            '</div>'+
            '</div>';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });

        var marker = new google.maps.Marker({
            position: pos,
            map: map
        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
    }

    $('#contact-form-ajax').on('submit', function(e) {
        e.preventDefault();

        var $this = $(this);

        $.ajax({
            method: $this.prop('method'),
            url: $this.prop('action'),
            data: $this.serialize(),
            dataType: 'json',
            beforeSend: function() {
                $('.contact-form-ajax-wrapper .alert-text').remove();
                $('.btn-p-container').addClass('disabled');
            }
        }).done(function() {
            $this.find('.cleanable').val(' ');
            $this.before('<p class="alert-text">Успешно изпратихте съобщение!</p>');
        }).fail(function(jqxhr) {
            if (jqxhr.status === 422) {
                $this.before('<p class="alert-text">Има грешки в попълнените данни!</p>');
                $.each(jqxhr.responseJSON, function(key, val) {
                    $('#contact-form-' + key).after('<span class="alert-text">' + val[0] + '</span>');
                });
            } else {
                $this.before('<p class="alert-text">Възникна някаква грешка!</p>');
            }
        }).always(function() {
            $('.btn-p-container').removeClass('disabled');
        });
    });

    $('.search-form .search-btn').on('click', function(e) {
        var $this = $(this);

        if (! $this.closest('.search-form').hasClass('open') || ! $this.siblings('input[name="s"]').val()) {
            e.preventDefault();
            $this.closest('.search-form').toggleClass('open');
        }
    });

    $('.close-alert').on('click', function() {
        $(this).parent().parent().fadeOut(400);
        $('.global-overlay').fadeOut(400);
    });

    $('.global-overlay').on('click', function() {
        $('.alert').fadeOut(400);
        $(this).fadeOut(400);
    });

    $('.delete-profile-btn').on('click', function(e) {
        e.preventDefault();

        $('.alert-delete-profile').fadeIn(400);
        $('.global-overlay').fadeIn(400);
    });

    $('.confirm-delete-profile').on('click', function() {
        $('#delete-profile-button').removeClass('delete-profile-btn');
        $('.alert-delete-profile').fadeOut(400);
        $('.global-overlay').fadeOut(400);
        $('#delete-profile-form').submit();
    });

    $('.cancel-delete-profile').on('click', function() {
        $('.alert-delete-profile').fadeOut(400);
        $('.global-overlay').fadeOut(400);
    });

});
